import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IMenuItems,
  IPopupItems,
} from "atic-common-helpers/helpers/interface.helper";
import { IUserSlice } from "interfaces/redux.interface";
import { IUser } from "interfaces/common.interface";

const initialUserState: IUserSlice = {
  token: null,
  user: {} as IUser,
  menuItems: [] as IMenuItems[],
  subMenuItems: [] as IMenuItems[],
  popupItems: [] as IPopupItems[],
  previousRoute: [],
  notiCount: 0,
  selected: [],
  dwNotiCount: 0,
  isSelectedStatusSame: false,
  selectedStatus: ""
};

const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setTokenRedux(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    setUserRedux(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
    },
    setMenuItemsRedux(state, action: PayloadAction<IMenuItems[]>) {
      state.menuItems = action.payload;
    },
    setSubMenuItemsRedux(state, action: PayloadAction<IMenuItems[]>) {
      state.subMenuItems = action.payload;
    },
    setPopupItemsRedux(state, action: PayloadAction<IPopupItems[]>) {
      state.popupItems = action.payload;
    },
    setPreviousRouteRedux(state, action: PayloadAction<string>) {
      state.previousRoute.push(action.payload);
    },
    setNotiCount(state, action: PayloadAction<number>) {
      state.notiCount = action.payload;
    },
    setDWNotiCount(state, action: PayloadAction<any>){
      state.dwNotiCount = action.payload;
    },
    setSelectedItems(state, action: PayloadAction<any>) {
      state.selected = action.payload;
    },
    setSelectedStatusSame(state, action: PayloadAction<any>) {
      state.isSelectedStatusSame = action.payload;
    },
    setSelectedStatusName(state, action: PayloadAction<any>) {
      state.selectedStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("LOGOUT", (state) => {
      Object.assign(state, initialUserState);
    });
  },
});

export const { setTokenRedux, setUserRedux, setMenuItemsRedux, setSubMenuItemsRedux, setPopupItemsRedux,setPreviousRouteRedux, 
  setNotiCount, setSelectedItems, setSelectedStatusSame, setSelectedStatusName, setDWNotiCount } =
  userSlice.actions;

export default userSlice.reducer;
