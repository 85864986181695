import { IPopupItems } from "atic-common-helpers/helpers/interface.helper";

export const driver: IPopupItems[] = [
  {
    path: "driver/profile",
    title: "Profile",
    icon: "profile",
  },
  {
    path: "driver/resetpassword",
    title: "Reset Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const driverVerify: IPopupItems[] = [
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const admin: IPopupItems[] = [
  {
    path: "admin/profile",
    title: "Profile",
    icon: "profile",
  },
  {
    path: `/resetpassword`,
    title: "Reset Password",
    icon: "settings",
    isChangePassword: true
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const broker: IPopupItems[] = [
  {
    path: "broker/profile",
    title: "Profile",
    icon: "profile",
  },
  {
    path: "broker/resetpassword",
    title: "Reset Password",
    icon: "settings",
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const MasterTypeEnum = {
  STATUS: "status",
  SERVICES: "services"
}

export const IRoles = {
  DRIVER: "profile",
  REP: "employee",
  LAWYER: "service_provider",
  ADMIN: "admin",
  VerifyDriver: "VerifyDriver",
  profile: 'profile',
  RepLawer: 'rep_service_provider'
}

export const Roles: any = {
  Driver: "driver",
  Lawyer: "lawyer",
  Rep: "employee",
  Admin: "admin",
  profile: "profile"
}


export const popupItem: IPopupItems[] = [
  {
    path: `/profile_details`,
    title: "Profile",
    icon: "profile",
  },
  {
    path: `${process.env.REACT_APP_DW_REDIRECTION_URL}login`,
    title: "My Wallet",
    icon: "badge",
    isBadge:true
  },
  {
    path: `/resetpassword`,
    title: "Reset Password",
    icon: "settings",
    isChangePassword: true
  },
  {
    title: "Logout",
    icon: "logout",
    isLogout: true,
  },
];

export const TICKET_STATUS = {
  CREATED: "Ticket Created",
  ASSIGNED: "Representative Assigned",
  INPROGRESS: "Inprogress",
  ONHOLD: "On Hold",
  LAWYER_REVIEW: "Pending Lawyer Review",
  TICKET_REJECTED: "Ticket Rejected",
  TICKET_APPROVED: "Ticket Accepted",
  // PAYMENT_PENDING: "Payment due",
  PAYMENT_DONE: "Payment completed",
  LAWYER_ASSIGNED: "Lawyer Assigned",
  WORK_INPROGRESS: "Work In Progress",
  IN_REVIEW: "Decision In Review",
  DECISION_AWARDED: "Decision Awarded",
  CLOSED: "Closed",
  WAITING: "Waiting"
};

export const PaymentStatus = {
  PENDING: "Pending",
  PAID: "Paid",
  FAILED: "Failed"
}
export const PaymentFilterStatus = [
  { name: "Payment Due", value: "Pending" },
  { name: "Payment Completed", value: "Paid" },
];

export const PaymentMethodFilterStatus = [
  { name: "Online", value: "Online" },
  { name: "Offline", value: "Offline" },
]

export const paymentOption = [
  { name: "UPI", value: "UPI" },
  { name: "Card", value: "Card" },
  { name: "Net Banking", value: "Net Banking" }
];

export const ticketPriority = [
  // {name:"Unset",value:"unset"},
  { name: "Low", value: "low" },
  { name: "Medium", value: "medium" },
  { name: "High", value: "high" },
]

// export const Driver_TICKET_STATUS : any = {
//   "Ticket Created": "Ticket Created",
//   "Representative Assigned": "Representative Assigned",
//   "Inprogress": "Representative Assigned",
//   "On Hold": "Representative Assigned",
//   "Pending Lawyer Review": "Representative Assigned",
//   "Ticket Rejected": "Representative Assigned",
//   "Ticket Accepted": "Representative Assigned",
//   "Lawyer Assigned": "Lawyer Assigned",
//   "Work In Progress": "Work In Progress",
//   "Decision In Review": "Work In Progress",
//   "Decision Awarded": "Decision Awarded",
//   "Closed": "Closed"
// };

export const Driver_TICKET_STATUS: any = {
  "Ticket Created": {
    name: "Ticket Created",
    color_code: "#283891"
  },
  "Representative Assigned": {
    name: "Representative Assigned",
    color_code: "#0090D6"
  },
  "Inprogress": {
    name: "Representative Assigned",
    color_code: "#0090D6"
  },
  "On Hold": {
    name: "Representative Assigned",
    color_code: "#0090D6"
  },
  "Pending Lawyer Review": {
    name: "Representative Assigned",
    color_code: "#0090D6"
  },
  "Ticket Rejected": {
    name: "Representative Assigned",
    color_code: "#0090D6"
  },
  "Ticket Accepted": {
    name: "Representative Assigned",
    color_code: "#0090D6"
  },
  "Lawyer Assigned": {
    name: "Lawyer Assigned",
    color_code: "#FDB137"
  },
  "Work In Progress": {
    name: "Lawyer Assigned",
    color_code: "#FDB137"
  },
  "Decision In Review": {
    name: "Lawyer Assigned",
    color_code: "#FDB137"
  },
  "Decision Awarded": {
    name: "Decision Awarded",
    color_code: "#82B379"
  },
  "Closed": {
    name: "Closed",
    color_code: "#F37088"
  },
};

export const ReassignUserOption = [
  {
    value: false,
    name: "Representative",
  },
  {
    value: true,
    name: "Lawyer",
  },
];

export const ProcessedUserOption = [
  {
    value: "rep",
    name: "Rep",
  },
  {
    value: "lawyer",
    name: "Lawyer Firm",
  },
];

/* ticket queue tab name */
export const ticketQueueTab = {
  general: 'general',
  assginedRep: 'assginedRep',
  assignedLawFirm: 'assignedLawFirm',
  myTickets: 'myTickets'
}

/* dmv license state */
export const DMVLicesnseStates = [
  {
    name: "Alabama",
    value: "AL"
  },
  {
    name: "Alaska",
    value: "AK"
  },
  {
    name: "Arizona",
    value: "AZ"
  },
  {
    name: "Arkansas",
    value: "AR"
  },
  {
    name: "California",
    value: "CA"
  },
  {
    name: "Colorado",
    value: "CO"
  },
  {
    name: "Connecticut",
    value: "CT"
  },
  {
    name: "Delaware",
    value: "DE"
  },
  {
    name: "Dist. of Columbia",
    value: "DC"
  },
  {
    name: "Florida",
    value: "FL"
  },
  {
    name: "Georgia",
    value: "GA"
  },
  {
    name: "Guam",
    value: "GU"
  },
  {
    name: "Hawaii",
    value: "HI"
  },
  {
    name: "Idaho",
    value: "ID"
  },
  {
    name: "Illinois",
    value: "IL"
  },
  {
    name: "Indiana",
    value: "IN"
  },
  {
    name: "Iowa",
    value: "IA"
  },
  {
    name: "Kansas",
    value: "KS"
  },
  {
    name: "Kentucky",
    value: "KY"
  },
  {
    name: "Louisiana",
    value: "LA"
  },
  {
    name: "Maine",
    value: "ME"
  },
  {
    name: "Maryland",
    value: "MD"
  },
  {
    name: "Massachusetts",
    value: "MA"
  },
  {
    name: "Michigan",
    value: "MI"
  },
  {
    name: "Minnesota",
    value: "MN"
  },
  {
    name: "Mississippi",
    value: "MS"
  },
  {
    name: "Missouri",
    value: "MO"
  },
  {
    name: "Montana",
    value: "MT"
  },
  {
    name: "Nebraska",
    value: "NE"
  },
  {
    name: "Nevada",
    value: "NV"
  },
  {
    name: "New Hampshire",
    value: "NH"
  },
  {
    name: "New Jersey",
    value: "NJ"
  },
  {
    name: "New Mexico",
    value: "NM"
  },
  {
    name: "New York",
    value: "NY"
  },
  {
    name: "North Carolina",
    value: "NC"
  },
  {
    name: "North Dakota",
    value: "ND"
  },
  {
    name: "Ohio",
    value: "OH"
  },
  {
    name: "Oklahoma",
    value: "OK"
  },
  {
    name: "Oregon",
    value: "OR"
  },
  {
    name: "Pennsylvania",
    value: "PA"
  },
  {
    name: "Puerto Rico",
    value: "PR"
  },
  {
    name: "Rhode Island",
    value: "RI"
  },
  {
    name: "South Carolina",
    value: "SC"
  },
  {
    name: "South Dakota",
    value: "SD"
  },
  {
    name: "Tennessee",
    value: "TN"
  },
  {
    name: "Texas",
    value: "TX"
  },
  {
    name: "Utah",
    value: "UT"
  },
  {
    name: "Vermont",
    value: "VT"
  },
  //## pls confirm the below one
  {
    name: "Virginia",
    value: "VA"
  },
  {
    name: "Washington",
    value: "WA"
  },
  {
    name: "West Virginia",
    value: "WV"
  },
  {
    name: "Wisconsin",
    value: "WI"
  },
  {
    name: "Wyoming",
    value: "WY"
  },
]