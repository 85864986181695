import { Button, Chip, Grid, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { commonService } from "atic-common-helpers/helpers/common.service";
import { Failed, Success } from "atic-common-helpers/helpers/toast.helper";
// import { MultiAutoCompleteField } from "atic-common-helpers/hoc/formfield";
import { sortArrayByProperty } from "atic-common-helpers/hooks/customHook";
import useForm from "atic-common-helpers/hooks/useForm";
import MainContainerWrapper from "atic-common-helpers/wrappers/MainContainerWrapper";
import {
  FilterFormDataValidation,
  OperatingLocationValidation,
} from "hooks/validateRules";
import { MultiAutoCompleteField } from "layout/hoc/formField";

import { useEffect, useState } from "react";
import Loader from "ui-component/Loader";

type LawFirmSettingType = {
  setIsModelOpen: any;
  width: number;
  buttonPosition: number;
};

const LawFirmSetting = ({
  setIsModelOpen,
  width,
  buttonPosition,
}: LawFirmSettingType) => {
  const initialValues: any = {
    operatingLocation: [],
    services: [],
  };

  const [locationLoading, setLocationLoading] = useState<boolean>(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  let [selectedServices, setSelectedServices] = useState([]);
  const [serviceOption, setServiceOption] = useState<any>([]);
  let [selectedOpLocation, setSelectedOpLocation] = useState([]);
  const [serviceOpLocationOption, setServiceOpLocationOption] = useState<any>(
    []
  );
  const theme = useTheme();

  const handleSubmitFL = () => {
    return new Promise((resolve, reject) => {
      setLocationLoading(true);
      commonService
        .postService(`lawyer`, {
          services: values.services.map((item: any) => item.value),
          operating_location: values.operatingLocation.map(
            (item: any) => item.value
          ),
        })
        .then((res: any) => {
          setLocationLoading(false);
          Success(res.data.message);
          setIsModelOpen(false);
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          setLocationLoading(false);
          Failed(error.response?.data?.error);
        });
    });
  };

  const handleSearchValueService = (search: any) => {
    getMasterServices(search);
  };

  const getMasterServices = (search: any = "") => {
    return new Promise((resolve, reject) => {
      setLocationLoading(true);
      let filter: any = {
        type: "services",
      };
      if (search) filter["search"] = search;
      commonService
        .postServiceParams(
          `master/search`,
          {
            filter,
          },
          {
            per_page: 30,
            page: 1,
            sort_order: "asc",
            sort_by: "name",
            // fieldset: "id,name"
          },
          true
        )
        .then((res: any) => {
          const data = res?.data?.data?.results?.map((item: any) => {
            return { name: item.name, value: item.id };
          });
          let service = [...selectedServices, ...data];
          if (service.length)
            service = [...selectedServices, ...data].sort(
              sortArrayByProperty("name")
            );
          setServiceOption(service);
          setLocationLoading(false);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          setLocationLoading(false);
          Failed(error.response.data.error);
        });
    });
  };

  const handleSearchValueLocation = (search: any) => {
    getOperatingLocation(search);
  };

  const getOperatingLocation = (search: any = "") => {
    return new Promise((resolve, reject) => {
      setLocationLoading(true);
      let filter: any = {
        country_code: 'US'
      };
      if (search) filter["search"] = search;
      commonService
        .postServiceParams(
          `/country-state/city/search`,
          {
            filter,
          },
          {
            per_page: 30,
            page: 1,
            sort_order: "asc",
            sort_by: "name",
            // fieldset: "id,name"
          },
          true
        )
        .then((res: any) => {
          const data = res?.data?.data?.results?.map((item: any) => {
            return { name: item?.name, value: item?.id };
          });
          let service = [...selectedOpLocation, ...data];
          if (service.length)
            service = [...selectedOpLocation, ...data].sort(
              sortArrayByProperty("name")
            );
          setServiceOpLocationOption(service);
          setLocationLoading(false);
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          setLocationLoading(false);
          Failed(error.response.data.error);
        });
    });
  };

  const { values, errors, handleChange, setUpdateValue, handleSubmit } =
    useForm(initialValues, handleSubmitFL, OperatingLocationValidation);

  const handleDelete = (ind: string, item: any) => {
    let filteredData;
    if (ind === "services") {
      filteredData = values.services.filter(
        (it: any) => it.value !== item.value
      );
      setUpdateValue("services", filteredData);
    } else if (ind === "operating_location") {
      filteredData = values.operatingLocation.filter(
        (it: any) => it.value !== item.value
      );
      setUpdateValue("operatingLocation", filteredData);
    }
  };

  const getExistingService = () => {
    return new Promise((resolve, reject) => {
      setLocationLoading(true);
      let filter: any = {};
      commonService
        .getServices(`lawyer`, true)
        .then((res: any) => {
          const serviceData = res?.data?.data?.services?.map((item: any) => {
            return { name: item.name, value: item.id };
          });
          let service = [...selectedServices, ...serviceData];
          if (service.length)
            service = [...selectedServices, ...serviceData].sort(
              sortArrayByProperty("name")
            );
          const locationData = res?.data?.data?.operating_location?.map(
            (item: any) => {
              return { name: item.name, value: item.id };
            }
          );
          let location = [...selectedOpLocation, ...locationData];
          if (location.length)
            location = [...selectedOpLocation, ...locationData].sort(
              sortArrayByProperty("name")
            );
          setUpdateValue("services", service);
          setUpdateValue("operatingLocation", location);
          //setServiceOption(service);
          setLocationLoading(false);
          //resolve(data);
        })
        .catch((error) => {
          console.log(error);
          setLocationLoading(false);
          Failed(error.response.data.error);
        });
    });
  };

  useEffect(() => {
    getExistingService();
  }, []);

  return (
    <MainContainerWrapper>
      {locationLoading && <Loader />}
      <form noValidate onSubmit={handleSubmit}>
        <Stack>
          <Grid container spacing={2} display="flex" flexDirection="row">
            <Grid item xl={width} lg={4} md={6} xs={12}>
              <MultiAutoCompleteField
                fieldName="Services"
                values={values?.services || []}
                name="services"
                handleInputChange={handleSearchValueService}
                errors={errors?.services}
                handleChange={(e: any) => setUpdateValue("services", e)}
                options={serviceOption}
                id="services"
                // size={"small"}
                //readOnly={actionType === 'View'}
                multiple={true}
                isRequired={true}
                loading={locationLoading}
                limit={15}
              />
            </Grid>
            <Grid item xl={width} lg={4} md={6} xs={12}>
              <MultiAutoCompleteField
                fieldName="Operating Locations"
                values={values?.operatingLocation || []}
                name="operatingLocation"
                handleInputChange={handleSearchValueLocation}
                errors={errors?.operatingLocation}
                handleChange={(e: any) =>
                  setUpdateValue("operatingLocation", e)
                }
                options={serviceOpLocationOption}
                id="operatingLocation"
                // size={"small"}
                //readOnly={actionType === 'View'}
                multiple={true}
                isRequired={true}
                loading={locationLoading}
                limit={15}
              />
            </Grid>
            <Grid
              item
              xl={buttonPosition}
              lg={6}
              md={6}
              xs={12}
              mt={4.5}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
            >
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  width: { md: "10%", xs: "100%" },
                  height: 50,
                  background: theme.palette.primary.primary700,
                }}
              >
                Save
              </Button>
            </Grid>
            {/* <Grid item xl={width} lg={6} md={6} xs={12}>
              <Box
                sx={{
                  padding: ".5rem 1rem",
                  width: "100%",
                  backgroundColor: "#f7f7f7",
                  height: 100,
                  maxHeight: 100,
                  overflowY: "auto",
                }}
              >
                {values.services
                  ? values?.services.map((item: any) => (
                      <Chip
                        id={item.id}
                        sx={{ margin: 1 }}
                        label={item.name}
                        variant="outlined"
                        onDelete={() => handleDelete("services", item)}
                      />
                    ))
                  : null}
              </Box>
            </Grid>

            <Grid item xl={width} lg={6} md={6} xs={12}>
              <Box
                sx={{
                  padding: ".5rem 1rem",
                  width: "100%",
                  backgroundColor: "#f7f7f7",
                  height: 100,
                  maxHeight: 100,
                  overflowY: "auto",
                }}
              >
                {values.operatingLocation
                  ? values.operatingLocation.map((item: any) => (
                      <Chip
                        id={item.id}
                        sx={{ margin: 1 }}
                        label={item.name}
                        variant="outlined"
                        onDelete={() =>
                          handleDelete("operating_location", item)
                        }
                      />
                    ))
                  : null}
              </Box>
            </Grid> */}
          </Grid>
          {/* <Stack
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{
                width: { md: "10%", xs: "100%" },
                background: theme.palette.primary.primary700,
              }}
            >
              Save
            </Button>
          </Stack> */}
        </Stack>
      </form>
    </MainContainerWrapper>
  );
};
export default LawFirmSetting;
