import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "./App.css";
import NavigationScroll from "./container/layout/NavigationScroll";
import RoutePath from "./routes";
import themes from "./asset/themes";
import { setAxiosDefauls } from "helpers/interceptor";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setPreviousRouteRedux, setUserRedux } from "store/slice/user.slice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { useAppDispatch, useAppSelector } from "store/store";
import { setNotiCount } from "store/slice/user.slice";
import { io } from "socket.io-client";


function App() {
  const customization = useAppSelector((state: any) => state.customization);
  
  setAxiosDefauls();
  const location = useLocation();
  const dispatch = useAppDispatch();

  /* get user details using redux */
  const user = useAppSelector((state) => state.user.user);

  /* socket useState */
  const [socket, connectSocket] = useState<any>(null);
  const [isSocket, setSocket] = useState(false);

  // useEffect(() => {                                                                                                                                                                                                                                                                                                                                                                                                                              
    // const fileName = location.pathname.split("/");
    // dispatch(setPreviousRouteRedux(`/${fileName[1]}/${fileName[2]}`));
  // }, [location]);

  useEffect(() => {
    if(socket && socket?.connected) return;
    if(user) {
    const socketConnect =  io(
        // process.env.REACT_APP_DC_END_URL ||
        `${process.env.REACT_APP_DOMAIN_URL}/api/socket/v1/events`,
        {
          path: "/api/socket/v1/events",
          auth: {
            authorization: `Bearer ${localStorage.getItem("at-fht-token")}`,
          },
          transports: ["websocket", "polling"],
        }
      );
    connectSocket(socketConnect);
    // dispatch(connectSocket(socketConnect));
  }
  }, [user]);


  useEffect(() => {
    function onConnect() {
      setSocket(true);
    }
    if (socket) {
      socket.on("connect", onConnect);
    }
  }, [socket]);

  useEffect(() => {

    if (isSocket) {
      socket.on("notification-count", (data:any) => {
        dispatch(setNotiCount(data?.count))
      });
    }
  }, [isSocket]);

  return (
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
          <RoutePath />
        </NavigationScroll>
      </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  );
}

export default App;
