import Chart from "react-apexcharts";

const BarChart = ({ barXLabels, barSeries }:any) => {
    const barState = {
        options: {
          chart: {
            id: "basic-bar",
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              barHeight: "80%", // Adjust the height of the bars
              barWidth: "50%", // Adjust the width of the bars and add space between them
            },
          },
          xaxis: {
            categories: barXLabels,
            title: {
              text: "Month", // X-axis label
              style: {
                fontWeight: "none", // Set font weight to bold
              },
            },
          },
          legend: {
            show: false, // Display legend
          },
          yaxis: {
            min: 0, // Set the minimum value of the y-axis to 0
            tickAmount: 5, // Display 5 ticks on the y-axis
            tickInterval: 200, // Set the interval between ticks to 200
            title: {
              text: "Users", // X-axis label
              style: {
                fontWeight: "none", // Set font weight to bold
              },
            },
          },
          colors: ["#004282", "#8aabcb", "#e6edf4"],
          dataLabels: {
            enabled: false, // Disable data labels
          },
        },
        series: barSeries
      };
      
  return (
    <Chart options={barState.options} series={barState.series} type="bar" />
  );
};

export default BarChart;
