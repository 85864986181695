import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import config from "./constant/config";
import { GoogleOAuthProvider } from '@react-oauth/google';

// style + assets
import "./index.scss";
import "./asset/scss/style.scss";
import "./asset/fonts/Poppins/Poppins-Regular.ttf";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { KeycloackContextProvider } from "pages/authentication/keycloak/useKeyCloak";
const container = document.getElementById("root")!;
const root = createRoot(container);


const GoogeClientID: any= process.env.REACT_APP_GOOGLE_CLIENTID

root.render(
  <GoogleOAuthProvider clientId={GoogeClientID}>
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
    <KeycloackContextProvider >
      <ToastContainer />
      <App />
      </KeycloackContextProvider>
    </BrowserRouter>
  </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
