import dayjs from "dayjs";
import { IPaginationArray } from "./interface.helper";

export const errorMessage = (error: any, defaultMessage: string): any => {
  if (error?.response?.data?.error) {
    if (Array.isArray(error?.response?.data?.error)) {
      return error?.response?.data?.error[0];
    }
    return error?.response?.data?.error;
  } else if (error?.data?.error) {
    return error?.data?.error;
  }
  return defaultMessage;
};

export const capitalizeFirstLetter = (string: string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const sortData = (
  array: any[],
  key: string,
  sortBy: string,
  type?: string
) => {
  // return array.sort((a, b) => compare(a, b, key, sortBy));
  if (sortBy === "asc") {
    if (type === "number") {
      return array.sort((a, b) => a[key] - b[key]);
    }
    return array.sort((a, b) => a[key]?.localeCompare(b[key]));
  } else {
    if (type === "number") {
      return array.sort((a, b) => b[key] - a[key]);
    }
    return array.sort((a, b) => b[key]?.localeCompare(a[key]));
  }
};

function compare(a: any, b: any, key: string, sortBy: string) {
  if (key === "dob") {
    if (new Date(a[key]) < new Date(b[key]) && sortBy === "desc") {
      return -1;
    }
    if (new Date(a[key]) > new Date(b[key]) && sortBy === "asc") {
      return 1;
    }
    return 0;
  }
  if (a[key] < b[key] && sortBy === "desc") {
    return -1;
  }
  if (a[key] > b[key] && sortBy === "asc") {
    return 1;
  }
  return 0;
}

export const pagination = ({
  array,
  page = 1,
  limit = 20,
}: IPaginationArray) => {
  const page_number = page && page > 0 ? page + 1 : page === 0 ? 1 : page;
  return array.slice((page_number - 1) * limit, page_number * limit);
};

export const cutFileName = (filename: string, maxLength: number, start: number, end: number): string => {
  if (filename.length <= maxLength) {
    return filename;
  }
  const truncatedName = filename.slice(0, start);
  return `${truncatedName}...${filename.slice(-end)}`;
}

/* set profile details */
export const setProfileDetail = (data: any) => {
  return {
    user_id: data?.id && data?.id,
    firstname: data?.firstname && data?.firstname,
    middlename: data?.middlename && data?.middlename,
    lastname: data?.lastname && data?.lastname,
    email: data?.email && data?.email,
    dob: data?.dob ? dayjs(data?.dob) : null as any,
    mobile_number: data?.mobile_number && data?.mobile_number,
    alt_mobile_number: data?.alt_mobile_number,
    license_number: data.license_number,
    dmv_license_state: data.dmv_license_state && data.dmv_license_state,
  }
}

/* find the object based on the value */
export const findValue = (value: any, key: any, arrayData: any) => {
  try {
    const findName = arrayData.find((item: any) => item[key.name] == value);
    const findValue = arrayData.find((item: any)=> item[key.value] == value);
    if(findName){
      return {name: findName};
    }
    if(findValue){
      return {value: findValue};
    }
    return {name: null, value: null};
  } catch (error) {
    return null;
  }
}

/* find the object based on the value */
export const findObject = (value: any, key: any, arrayData: any) => {
  try {
    const findObject = arrayData.find((item: any) => item[key] == value)
    return findObject;
  } catch (error) {
    return {
      name: null,
      value: null
    }
  }
}