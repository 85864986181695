import { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DonutChart = ({donutLabels,donutSeries}:any) => {

  const donutState = {
    options: {
      colors: ["#004282", "#8aabcb", "#e6edf4"],
      plotOptions: {
        pie: {
          donut: {
            size: "50%", // Set the size of the donut chart
          },
        },
      },
      dataLabels: {
        enabled: false, // Disable data labels
      },
    
      labels: donutLabels,
      legend: {
        //floating:true,
        show: true, // Display legend
        // position: "right", // Set legend position
        labels: {
          colors: "#333", // Customize legend label color
        },
      },
    
    },
    series: donutSeries,
  };

  return (
    <Chart
      options={donutState.options}
      series={donutState.series}
      type="donut"
    />
  );
};

export default DonutChart;
