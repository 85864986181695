import Charts from "pages/charts";
import LawFirmSetting from "pages/lawfirmsetting";
import { lazy, Suspense } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { useAppSelector } from "store/store";
import { Roles, IRoles } from "utils/constant.utils";

const Loader = lazy(() => import("ui-component/Loader"));
const PageNotFound = lazy(() => import("../pages/pagenotfound"));
const Login = lazy(() => import("../pages/authentication/Login"));
const Registration = lazy(() => import("../pages/authentication/Registration"));
const ForgotPassword = lazy(() => import("../pages/authentication/ForgotPassword"));
const SetPassword = lazy(() => import("../pages/authentication/SetPassword"));
const VerifyEmail = lazy(() => import("../pages/authentication/VerifyEmail"));

/* coming soon */
const ComingSoon = lazy(()=>import("../layout/hoc/coming-soon"));

// const Layout = lazy(() => import("../atic-common-helpers/layout/MainLayout"));
const Layout = lazy(() => import("../layout/layout/MainLayout"));
const Dashboard = lazy(() => import("../pages/dashboard"));
const TicketType = lazy(() => import("../pages/admin/master/tickettype"));
const Service = lazy(() => import("../pages/admin/master/service"));
const MasterIndex = lazy(() => import("../pages/admin/master/MasterIndex"));
const Location = lazy(() => import("../pages/admin/master/location"));
const LawyerList = lazy(() => import("../pages/admin/usermanagement/lawyer"));
const RepresentativeList = lazy(() => import("../pages/admin/usermanagement/representatives"));
const ChangePassword = lazy(() => import('../pages/authentication/ChangePassword'));
const AddEditrepresentative = lazy(() => import('pages/admin/usermanagement/representatives/addEditrepresentative'));
const AddEditLawyer = lazy(() => import('pages/admin/usermanagement/lawyer/addEditLawyer'));
const AddEditTicket = lazy(() => import("pages/ticket/AddEditTicket"));
const RepEditTicket = lazy(() => import("pages/ticket/edit/RepTicket"));
const AdminEditTicket = lazy(() => import("pages/ticket/edit/AdminTicket"));
const ViewTicket = lazy(() => import("pages/ticket/view/ViewTicket"));
// const ProfileView = lazy(() => import("pages/admin/profile/ProfileView"));
const ProfileView = lazy(() => import("../layout/pages/profile-details/profile-basic-details"));
const ProfileEdit = lazy(() => import("../layout/pages/profile-details/profile-basic-edit"));
const LawyerProfile = lazy(() => import("pages/admin/profile/LawyerProfile"));
const LawyerRequest = lazy(() => import('pages/lawyer/LawyerRequest'));
const LawyerRequestViewTicket = lazy(() => import('pages/ticket/view/LawyerRequestViewTicket'));
const LawyerViewTicket = lazy(() => import('pages/ticket/view/LawyerViewTicket'));
const LawyerEditTicket = lazy(() => import('pages/ticket/edit/LawyerTicket'));
const DriverAddEditTicket = lazy(() => import('pages/driver/DriverTicket'));
const DriverVerfication = lazy(() => import('pages/driver/verification'));
const Queue = lazy(() => import('pages/queues'));
const AdminTicketQueue = lazy(() => import('pages/queues/AdminTicketQueue'));
const ProfileTicketQueue = lazy(() => import('pages/queues/ProfileTicketQueue'));
const HistoryQueue = lazy(() => import('pages/queues/DriverHistoryQueue'));
const ProcessedQueue = lazy(() => import('pages/queues/ProcessedQueue'));
const Reports = lazy(() => import('pages/admin/report/Report'));
const DriverViewTicket = lazy(() => import('pages/ticket/view/DriverViewTicket'));
const DriverSupport = lazy(() => import('pages/driver/support'));
const ViewAllNotifications = lazy(() => import('pages/notification/index'));
const DriverList = lazy(() => import('pages/admin/usermanagement/driver/DriverList'));
const DriverView = lazy(() => import('pages/admin/usermanagement/driver/Viewdriver'));

const { ADMIN, DRIVER, REP, LAWYER, profile } = IRoles;

const GuestRoute = (props: any) => {
  // const { auth } = useAuth()
  const auth = useAppSelector((state) => state.user.user);
  let location = useLocation();
  return props?.roles?.includes(auth?.role) ? (
    <Navigate
      // to={`/${Roles[auth?.role]}/dashboard`}
      to={`/dashboard`}
      state={{ from: location }}
      replace
    />
  ) : (
    <Outlet />
  );
};

// const ProtectedUserRoute = (props: any) => {
//   const auth = useAppSelector((state) => state.user.user);
//   let location = useLocation();
//   console.log('auth?.role)', auth?.role);
//   console.log('props?.roles)', props?.roles);
//   return props?.roles?.includes(auth?.role) ? (
//     <Outlet />
//   ) : auth?.user ? (
//     <Navigate
//       // to={`/${Roles[auth?.role]}/dashboard`}
//       to={`${IRoles.profile}/dashboard`}
//       state={{ from: location }}
//       replace
//     />
//   ) : (
//     <Navigate to="/login" state={{ from: location }} replace />
//   );
// };

const ProtectedUserRoute = (props: any) => {
  const auth = !!localStorage.getItem("at-fht-user")
    ? JSON.parse(localStorage.getItem("at-fht-user") || "")
    : null;
  let location = useLocation();
  return props?.roles.some((r: any) => auth?.role?.includes(r)) ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate to={`/${IRoles.profile}/dashboard`} state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

// driver role after verification authentication
const ProductDriverRoute = (props: any) => {
  const user = useAppSelector((state) => state.user.user);
  const location = useLocation();
  if (user && Object.keys(user).length === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (user?.is_mobile_verified) {
    return <Navigate to={`/${IRoles.profile}/dashboard`} state={{ from: location }} replace />;
  } else if ((props?.roles?.includes(user?.role)) || !user?.is_mobile_verified) {
    return <Outlet />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

// driver role before verification authentication
const ProductVerifiedDriver = (props: any) => {
  const user = useAppSelector((state) => state.user.user);
  let location = useLocation();
  if (user && Object.keys(user).length === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!props?.roles?.includes(user?.role)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  } else if (!user?.is_mobile_verified) {
    return (
      <Navigate to={`/${IRoles.profile}/verification`} state={{ from: location }} replace />
    );
  } else if (props?.roles?.includes(user?.role)) {
    return <Outlet />;
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

const RoutePath = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/">
          <Route path="*" element={<PageNotFound />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to="login" replace />} />
          <Route path="/verify_email" element={<VerifyEmail />} />
          <Route element={<GuestRoute roles={[ADMIN, DRIVER, REP, LAWYER]} />}>
            <Route path="/login" element={<Login />} />
            <Route path="/backoffice/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            {/* <Route path="/verify_email" element={<VerifyEmail />} /> */}
            <Route path="/setpassword/:verifyToken" element={<SetPassword />} />
          </Route>
          <Route element={<ProtectedUserRoute roles={[ADMIN, REP, LAWYER, DRIVER, profile]} />}>
            <Route element={<Layout />} >
              {/* common protected route */}
              <Route path={`/profile_details`} element={<ProfileView />} />
              <Route path={`/profile_edit`} element={<ProfileEdit />} />
              <Route path={`/support`} element={<DriverSupport />} />

              <Route path={`/coming_soon`} element={<ComingSoon />} />
              <Route path={`/dashboard/coming_soon`} element={<Charts />} />
              <Route path={`/member_portal/coming_soon`} element={<ComingSoon />} />
              <Route path={`/my_business/coming_soon`} element={<ComingSoon />} />
              <Route path={`/reports/coming_soon`} element={<ComingSoon />} />
              <Route path={`/help/coming_soon`} element={<ComingSoon />} />
              <Route path={`/coming_soon_1`} element={<ComingSoon />} />
              <Route path={`/coming_soon_2`} element={<ComingSoon />} />
              <Route path={`${IRoles.profile}/view-all-notification`} element={<ViewAllNotifications />} />

              <Route element={<ProtectedUserRoute roles={[ADMIN]} />}>
                <Route path={`${Roles.Admin}/dashboard`} element={<Dashboard />} />
                <Route path={`${Roles.Admin}/ticketqueue`} element={<AdminTicketQueue />} />
                <Route path={`${Roles.Admin}/usermanagement/representatives`} element={<RepresentativeList />} />
                <Route path={`${Roles.Admin}/usermanagement/lawyer`} element={<LawyerList />} />
                <Route path={`${Roles.Admin}/usermanagement/driver`} element={<DriverList />} />
                <Route path={`${Roles.Admin}/usermanagement/createreprenstative`} element={<AddEditrepresentative />} />
                <Route path={`${Roles.Admin}/usermanagement/viewrepresentative/:id`} element={<AddEditrepresentative />} />
                <Route path={`${Roles.Admin}/usermanagement/editrepresentative/:id`} element={<AddEditrepresentative />} />
                <Route path={`${Roles.Admin}/usermanagement/createlawyer`} element={<AddEditLawyer />} />
                <Route path={`${Roles.Admin}/usermanagement/viewlawyer/:id`} element={<AddEditLawyer />} />
                <Route path={`${Roles.Admin}/usermanagement/view-driver/:id`} element={<DriverView />} />

                <Route path={`${Roles.Admin}/usermanagement/editlawyer/:id`} element={<AddEditLawyer />} />
                <Route path={`${Roles.Admin}/master/tickettype`} element={<TicketType />} />
                {/* <Route path={`${Roles.Admin}/master/service`} element={<Service />} /> */}
                <Route path={`${Roles.Admin}/master/service`} element={<MasterIndex />} />
                <Route path={`${Roles.Admin}/siteadministration/adminusers`} element={<Location />} />
                <Route path={`${Roles.Admin}/Reports`} element={<Reports />} />
                <Route path={`${Roles.Admin}/resetpassword`} element={<ChangePassword />} />
                <Route path={`${Roles.Admin}/create-ticket`} element={<AddEditTicket />} />
                <Route path={`${Roles.Admin}/edit-ticket/:id`} element={<AdminEditTicket />} />
                <Route path={`${Roles.Admin}/view-ticket/:id`} element={<ViewTicket />} />
                <Route path={`${Roles.Admin}/view-all-notification`} element={<ViewAllNotifications />} />

              </Route>

              {/* prducted route for all user */}
              <Route element={<ProtectedUserRoute roles={[LAWYER, REP, profile]} />}>
                <Route path={`${IRoles.profile}/dashboard`} element={<Dashboard />} />
                <Route path={`${IRoles.profile}/ticketqueue`} element={<ProfileTicketQueue />} />
                <Route path={`${IRoles.profile}/settings`} element={<LawFirmSetting setIsModelOpen={false} width={5} buttonPosition={2}/>} />
                <Route path={`${IRoles.profile}/support`} element={<DriverSupport />} />
                <Route path={`${IRoles.profile}/view-all-notification`} element={<ViewAllNotifications />} />

                {/* Rep badge roles */}
                <Route path={`${IRoles.profile}/create_ticket`} element={<AddEditTicket />} />
                <Route path={`${IRoles.profile}/${IRoles.REP}/view_ticket/:id`} element={<ViewTicket />} />
                <Route path={`${IRoles.profile}/${IRoles.REP}/processed/view_ticket/:id`} element={<ViewTicket />} />
                <Route path={`${IRoles.profile}/${IRoles.REP}/edit_ticket/:id`} element={<RepEditTicket />} />

                {/* lawyer badges pages */}
                <Route path={`${IRoles.profile}/${IRoles.LAWYER}/view_ticket/:id`} element={<LawyerViewTicket />} />
                <Route path={`${IRoles.profile}/${IRoles.LAWYER}/processed/view_ticket/:id`} element={<LawyerViewTicket />} />
                <Route path={`${IRoles.profile}/${IRoles.LAWYER}/edit_ticket/:id`} element={<LawyerEditTicket />} />
                <Route path={`${IRoles.profile}/requests`} element={<LawyerRequest />} />
                <Route path={`${IRoles.profile}/requestsview/:id`} element={<LawyerRequestViewTicket />} />

                {/* profile badge pages */}
                <Route path={`${IRoles.profile}/create_ticket`} element={<AddEditTicket />} />
                <Route path={`${IRoles.profile}/view_ticket/:id`} element={<DriverViewTicket />} />
                <Route path={`${IRoles.profile}/edit_ticket/:id`} element={<DriverAddEditTicket />} />

                {/* Processed pages both lawer and rep badges */}
                <Route path={`${IRoles.profile}/processed`} element={<ProcessedQueue />} />

              </Route>

              {/* <Route element={<ProtectedUserRoute roles={[REP]} />}>
                <Route path={`${Roles.Rep}/dashboard`} element={<Dashboard />} />
                <Route path={`${Roles.Rep}/ticketqueue`} element={<Queue />} />
                <Route path={`${Roles.Rep}/create-ticket`} element={<AddEditTicket />} />
                <Route path={`${Roles.Rep}/edit-ticket/:id`} element={<RepEditTicket />} />
                <Route path={`${Roles.Rep}/view-ticket/:id`} element={<ViewTicket />} />
                <Route path={`${Roles.Rep}/processed`} element={<ProcessedQueue />} />
                <Route path={`${Roles.Rep}/resetpassword`} element={<ChangePassword />} />
                <Route path={`${Roles.Rep}/profileDetails`} element={<ProfileView />} />
                <Route path={`${Roles.Rep}/view-all-notification`} element={<ViewAllNotifications />} />

              </Route>
              <Route element={<ProtectedUserRoute roles={[LAWYER]} />}>
                <Route path={`${Roles.Lawyer}/dashboard`} element={<Dashboard />} />
                <Route path={`${Roles.Lawyer}/ticketqueue`} element={<Queue />} />
                <Route path={`${Roles.Lawyer}/processed`} element={<ProcessedQueue />} />
                <Route path={`${Roles.Lawyer}/requests`} element={<LawyerRequest />} />
                <Route path={`${Roles.Lawyer}/requestsview/:id`} element={<LawyerRequestViewTicket />} />
                <Route path={`${Roles.Lawyer}/resetpassword`} element={<ChangePassword />} />
                <Route path={`${Roles.Lawyer}/profileDetails`} element={<LawyerProfile />} />
                <Route path={`${Roles.Lawyer}/view-ticket/:id`} element={<LawyerViewTicket />} />
                <Route path={`${Roles.Lawyer}/edit-ticket/:id`} element={<LawyerEditTicket />} />
                <Route path={`${Roles.Lawyer}/view-all-notification`} element={<ViewAllNotifications />} />

              </Route>
              <Route element={<ProductDriverRoute roles={[DRIVER]} />}>
                <Route
                  path={`/${Roles.Driver}/verification`}
                  element={<DriverVerfication />}
                />
              </Route>
              <Route element={<ProductVerifiedDriver roles={[DRIVER]} />}>
                <Route path={`${Roles.Driver}/dashboard`} element={<Dashboard />} />
                <Route path={`${Roles.Driver}/ticketqueue`} element={<Queue />} />
                <Route path={`${Roles.Driver}/history`} element={<HistoryQueue />} />
                <Route path={`${Roles.Driver}/support`} element={<DriverSupport />} />
                <Route path={`${Roles.Driver}/resetpassword`} element={<ChangePassword />} />
                <Route path={`${Roles.Driver}/profileDetails`} element={<ProfileView />} />
                <Route path={`${Roles.Driver}/create-ticket`} element={<DriverAddEditTicket />} />
                <Route path={`${Roles.Driver}/edit-ticket/:id`} element={<DriverAddEditTicket />} />
                <Route path={`${Roles.Driver}/view-ticket/:id`} element={<DriverViewTicket />} />
                <Route path={`${Roles.Driver}/view-all-notification`} element={<ViewAllNotifications />} />
              </Route> */}
            </Route>
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default RoutePath;
