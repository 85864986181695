import { mixed, addMethod } from "yup";
import * as yup from "yup";
import dayjs from 'dayjs';
import { PhoneNumberUtil } from "google-libphonenumber";
const licenseDriverRegex = RegExp(/^[A-Za-z0-9]{5,11}$/);

addMethod(mixed, "isDateValid", isDateValid);
const phoneNumberUtil = PhoneNumberUtil.getInstance();
// Custom Yup validation method for phone number validation

const VINRegex = RegExp(/^[A-Za-z0-9]{17}$/);
const phoneSchema = (isRequired: boolean) => yup
  .string()
  .test("phone", "Invalid phone number", function (value) {
    if (!value) return true; // Allow empty values
    if (value.length <= 3 && !isRequired) {
      return true;
    }
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  });

function getErrorsFromValidationError(validationError: any) {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors: any, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
}

function handleErrorMeg(msg: string, schema: any) {
  try {
    schema.validateSync(msg, { abortEarly: false });
    return {};
  } catch (error) {
    return getErrorsFromValidationError(error);
  }
}

function isDateValid(msg: string) {
  return mixed().test("isDateValid", msg, function (value: any) {
    value = parseInt(value, 10);
    if (!value || isNaN(value)) return false;
    var isValid = new Date(value).getTime() > 0;
    return isValid;
  });
}

const emailRegex = RegExp(
  /^[\w]{1,}[\w.+-]{0,}@[\w-]{2,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/
);

const passwordPattern =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,16})$/;

export function AddMasterDataValidation(values: any) {
  return handleErrorMeg(values, CreateAddMasterDataSchema);
}

const CreateAddMasterDataSchema = yup.object().shape({
  name: yup
    .string()
    .max(50, "Max 50 Characters")
    .required("Name is Required")
    .nullable(),
  // type: yup.object().required('Type is Required').nullable(),
  type: yup.object().required("Type is Required").typeError("Type is Required"),
});

export function LoginValidation(values: any) {
  return handleErrorMeg(values, LoginSchema);
}

export function RegistrationValidation(values: any) {
  return handleErrorMeg(values, RegistrationSchema);
}

export const RegistrationSchema = yup.object().shape({
  full_name: yup
  .string()
  .trim()
  .max(50, "Max 50 Characters")
  .required("Full Name is Required"),
  email: yup
    .string().max(50, 'Max 50 Characters')
    .matches(emailRegex, "Invalid Email ID")
    .required("Email ID is Required"),
    mobile_number: yup.string().test("mobile_number", "Invalid Mobile Phone", function (value) {
      if (!value) return true; // Allow empty values
      try {
        const phoneNumber = phoneNumberUtil.parse(value);
        return phoneNumberUtil.isValidNumber(phoneNumber);
      } catch (error) {
        return false;
      }
    }).required("Mobile Phone is Required"),
});

export function forgetPasswordValidation(value: any) {
  return handleErrorMeg(value, forgetPasswordSchema);
}

export const forgetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Invalid Email ID")
    .required("Email ID is Required"),
});

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .matches(emailRegex, "Invalid Email ID")
    .required("Email ID is Required"),
  password: yup
    .string()
    .min(8, "Password Must Be At Least 8 Characters")
    .required("Password is Required"),

});

export function setPasswordValidation(values: any) {
  return handleErrorMeg(values, setPasswordSchema);
}

export function resetPasswordValidation(values: any) {
  return handleErrorMeg(values, resetPasswordSchema);
}

export function changePasswordValidation(values: any) {
  return handleErrorMeg(values, changePasswordSchema);
}

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .matches(passwordPattern, "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )")
    .min(8, "Password Must Be At Least 8 Characters")
    .max(16, "Password Must Be aAt Most 32 Characters")
    .required("Old Password is Required"),
  password: yup
    .string()
    .matches(passwordPattern, "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )")
    .required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords Must Match"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(passwordPattern, "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )")
    .required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords Must Match"),
});

export const setPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .matches(passwordPattern, "Password Should Contain 8 Characters ( 1 Upper Case , 1 Lower Case , 1 Special Character (@, $, !, %, *, ?, or &) and 1 Numeric Value )")
    .required("Password is Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords Must Match"),
});

export function DateValidation(values: any) {
  return handleErrorMeg(values, DateValidationSchema);
}
export const DateValidationSchema=yup.object().shape({
  from:yup.date().required(" From Date is Required").typeError('From Date is Required'),
  to:yup.date().min(yup.ref('from'), "To Date Must Be Greater Than From Date").required(" To Date is Required").typeError("From Date is Required")
})

export function MasterDataValidation(values: any) {
  return handleErrorMeg(values, MasterDataValidationSchema);
}

export const MasterDataValidationSchema=yup.object().shape({
  name:yup.string().trim().max(50, "Max 50 Characters").required('Ticket Type is Required'),
  is_active:yup.boolean()
})

export function AddRepDataValidation(values: any) {
  return handleErrorMeg(values, AddRepDataValidationSchema);
}

export const AddRepDataValidationSchema=yup.object().shape({
  full_name:yup.string().trim().max(50, "Max 50 Characters").min(3,'Min 3 Characters').required('Name is Required'),
  mobile_number: yup.string().test("mobile_number", "Invalid Mobile Phone", function (value) {
    if (!value) return true; // Allow empty values
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  }).required("Mobile Phone is Required"),
  email: yup
  .string().max(50,'Max 50 Characters')
  .matches(emailRegex, "Invalid Email ID")
  .required("Email ID is Required"),
  alt_mobile_number: phoneSchema(false).nullable().optional(),
  fax:phoneSchema(false).nullable().optional(),
  dob:yup.date().nullable().required(`Date of Birth is Required`).typeError('Date of Birth is Required'),
  license_number:yup.string().matches(licenseDriverRegex, "Invalid License Number").required("License Number is Required"),
  company: yup.string().nullable().optional(),
})

export function RepDataValidation(values: any) {
  return handleErrorMeg(values, RepDataValidationSchema);
}

export const RepDataValidationSchema=yup.object().shape({
  full_name:yup.string().trim().max(50, "Max 50 Characters").required('Name is Required').min(3,"Min 3 Characters"),
  mobile_number: yup.string().test("mobile_number", "Invalid Mobile Phone", function (value) {
    if (!value) return true; // Allow empty values
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  }).required("Mobile Phone is Required"),
  email: yup
  .string().max(50,'Max 50 Characters')
  .matches(emailRegex, "Invalid Email ID")
  .required("Email ID is Required"),
})

export function AddLawyerDataValidation(values: any) {
  return handleErrorMeg(values, AddLawyerDataValidationSchema);
}



export const AddLawyerDataValidationSchema=yup.object().shape({
  firm_name:yup.string().trim().max(50, "Max 50 Characters").min(3,"Min 3 Characters").required('Frim Name is Required'),
  mobile_number: yup.string().test("mobile_number", "Invalid Mobile Phone", function (value) {
    if (!value) return true; // Allow empty values
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  }).required("Mobile Phone is Required"),
  email: yup
  .string().max(50,'Max 50 Characters')
  .matches(emailRegex, "Invalid Email ID")
  .required("Email ID is Required"),
  services:yup.array()
  .of(
    yup.object().shape({
      value: yup.string().required('Please select a value.'), // Customize your validation rules
      name: yup.string().required('Please select a value.'),  // Customize your validation rules
    })
  )
  .min(1, 'Services is Required.'),
  operating_location: yup.array()
  .of(
    yup.object().shape({
      value: yup.string().required('Please select a value.'), // Customize your validation rules
      name: yup.string().required('Please select a value.'),  // Customize your validation rules
    })
  )
  .min(1, 'Operation Location is Required.'),
  // string().trim().required('Operation Location is Required'),
})

export function AddTicketDataValidation(values: any) {
  return handleErrorMeg(values, AddTicketDataValidationSchema);
}

export const AddTicketDataValidationSchema=yup.object().shape({
  firstname: yup
  .string()
  .trim()
  .max(50, "Max 50 characters")
  .required("First Name is required"),
  middlename: yup.string().trim().max(50, "Max 50 characters").nullable(),
  lastname: yup
  .string()
  .trim()
  .max(50, "Max 50 characters")
  .required("Last Name is required"),
  // full_name:yup.string().trim().max(50, "Max 50 Characters").min(3,"Min 3 Characters").required('Full Name is Required'),
  mobile_number: yup.string().test("mobile_number", "Invalid Mobile Number", function (value) {
    if (!value) return true; // Allow empty values
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  }).required("Mobile Phone is Required"),
  email: yup
  .string().max(50,'Max 50 Characters')
    .transform((originalValue, originalObject) => {
      // If the value is an empty string, transform it to null
      return originalValue === "" ? null : originalValue;
    })
    .matches(emailRegex, "Invalid Email Id")
    .nullable()
    .optional(),
  alt_mobile_number: phoneSchema(false).nullable().optional(),
  fax:phoneSchema(false).nullable().optional(),
  dob:yup.date().required(`Date of Birth is Required`).typeError('Date of Birth is Required'),
  license_number:yup.string().matches(licenseDriverRegex, "Invalid License Number").required(`DMV License Number is Required`),
  dmv_license_state: yup
    .object()
    .required("DMV License State is required")
    .typeError("DMV License State is required"),
  company: yup.string().nullable().optional(),
  ticket_type:yup.object().required('Ticket Type is Required'),
  ticket_date:yup.date().required(`Ticket Date is Required`).typeError('Ticket Date is Required'),
  subject:yup.string().trim().optional().nullable(),
  location:yup.object().required('County is Required'),
  police_officer_name:yup.string().trim().optional().nullable(),
  vin_number: yup
  .string()
  .typeError("Vehicle Identification Number(VIN) is required")
  .required("Vehicle Identification Number(VIN) is required"),
  priority:yup.string().trim().optional().nullable(),
  dmv_license_plate_number: yup
  .string()
  .nullable(),
  description:yup.string().trim().optional().nullable(),
  ticket_unique_id:yup
  .string()
  .trim()
  .min(10,"Min 10 characters")
  .max(10, "Max 10 characters")
  .required("Traffic Ticket Number is required"),
})

export function IsDriverExistAddTicketDataValidation(values: any) {
  return handleErrorMeg(values, IsDriverExistAddTicketDataValidationSchema);
}


export const IsDriverExistAddTicketDataValidationSchema=yup.object().shape({
  user_id:yup.object().required('Driver is Required'),
  // full_name:yup.string().nullable().optional(),
  firstname: yup
  .string()
  .trim()
  .max(50, "Max 50 characters")
  .required("First Name is required"),
  middlename: yup.string().trim().max(50, "Max 50 characters").nullable(),
  lastname: yup
  .string()
  .trim()
  .max(50, "Max 50 characters")
  .required("Last Name is required"),
  mobile_number: yup.string().test("mobile_number", "Invalid Mobile Number", function (value) {
    if (!value) return true; // Allow empty values
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  }).optional(),
  email: yup
  .string().max(50,'Max 50 Characters')
    .transform((originalValue, originalObject) => {
      // If the value is an empty string, transform it to null
      return originalValue === "" ? null : originalValue;
    })
    .matches(emailRegex, "Invalid Email Id")
    .nullable()
    .optional(),
  alt_mobile_number: phoneSchema(false).nullable().optional(),
  fax:phoneSchema(false).nullable().optional(),
  // dob:yup.date().nullable().optional(),
  dob:yup.date().required(`Date of Birth is Required`).typeError('Date of Birth is Required'),
  // license_number:yup.string().nullable().optional(),
  license_number:yup.string().matches(licenseDriverRegex, "Invalid License Number").required(`DMV License Number is Required`),
  dmv_license_state: yup
    .object()
    .required("DMV License State is required")
    .typeError("DMV License State is required"),
  company: yup.string().nullable().optional(),
  ticket_type:yup.object().required('Ticket Type is Required'),
  ticket_date:yup.date().required(`Ticket Date is Required`).typeError('Ticket Date is Required'),
  subject:yup.string().trim().optional().nullable(),
  location:yup.object().required('County is Required'),
  police_officer_name:yup.string().trim().optional().nullable(),
  vin_number: yup
  .string()
  .typeError("Vehicle Identification Number(VIN) is required")
  .required("Vehicle Identification Number(VIN) is required"),
  dmv_license_plate_number: yup
  .string()
  .nullable(),
  description:yup.string().trim().optional().nullable(),
  priority:yup.string().trim().optional().nullable(),
  ticket_unique_id:yup
  .string()
  .trim()
  .min(10,"Min 10 characters")
  .max(10, "Max 10 characters")
  .required("Traffic Ticket Number is required"),
})



export function ViewTicketDataValidation(values: any) {
  return handleErrorMeg(values, ViewTicketDataValidationSchema);
}

export const ViewTicketDataValidationSchema=yup.object().shape({
  description:yup.string().trim().max(50, "Max 50 Characters").required('Description is Required'),
})

export function QueueActionDataValidation(values: any) {
  return handleErrorMeg(values, QueueActionDataValidationSchema );
}

export const QueueActionDataValidationSchema=yup.object().shape({
  isAssgnee: yup.boolean(),
  status:yup.object().required('Status is Required'),
  // assignee:yup.object().required('Assignee is Required'),
  assignee: yup.string().when('isAssgnee', {
    is: true,
    then: () => yup.object().required('Assignee is Required'),
    otherwise: () => yup.object().nullable().optional()
  }),
})

export function ReasignQueueActionValidation(values: any) {
  return handleErrorMeg(values, ReassignQueueActionValidationSchema );
}

export const ReassignQueueActionValidationSchema=yup.object().shape({
  assignee: yup.object().required('Assignee is Required').typeError('Assignee is Required')
})

export function MasterLocationValidation(values: any) {
  return handleErrorMeg(values, MasterLocationValidationSchema);
}

export const MasterLocationValidationSchema=yup.object().shape({
  category: yup.string().trim().max(50, "Max 50 Characters").required('Title is Required'),
  city:yup.array()
  .of(
    yup.object().shape({
      value: yup.string().required('Please select a value.'), // Customize your validation rules
      name: yup.string().required('Please select a value.'),  // Customize your validation rules
    })
  )
  .min(1, 'Location is Required.'),
  cost: yup.number().min(50, "Amount must be greater than $50").max(999, "Amount must be less than $999").required("Amount is Required").typeError("Amount is Required"),
  law:yup.array()
  .of(
    yup.object().shape({
      value: yup.string().required('Please select a value.'), // Customize your validation rules
      name: yup.string().required('Please select a value.'),  // Customize your validation rules
    })
  )
  .min(1, 'Lawyer is Required.'),
})

export function DriverCreationValidation(values: any) {
  return handleErrorMeg(values, DriverCreationValidationSchema);
}

export const DriverCreationValidationSchema=yup.object().shape({
  user_id:yup.string().required('Driver is Required'),
  // full_name:yup.string().nullable().optional(),
  firstname: yup
  .string()
  .trim()
  .max(50, "Max 50 characters")
  .required("First Name is required"),
  middlename: yup.string().trim().max(50, "Max 50 characters").nullable(),
  lastname: yup
  .string()
  .trim()
  .max(50, "Max 50 characters")
  .required("Last Name is required"),
  mobile_number: yup.string().test("mobile_number", "Invalid Mobile Number", function (value) {
    if (!value) return true; // Allow empty values
    try {
      const phoneNumber = phoneNumberUtil.parse(value);
      return phoneNumberUtil.isValidNumber(phoneNumber);
    } catch (error) {
      return false;
    }
  }).optional(),
  email: yup
  .string().max(50,'Max 50 Characters')
    .transform((originalValue, originalObject) => {
      // If the value is an empty string, transform it to null
      return originalValue === "" ? null : originalValue;
    })
    .matches(emailRegex, "Invalid Email Id")
    .nullable()
    .optional(),
  alt_mobile_number: phoneSchema(false).nullable().optional(),
  fax:phoneSchema(false).nullable().optional(),
  // dob:yup.date().nullable().optional(),
  dob:yup.date().required(`Date of Birth is Required`).typeError('Date of Birth is Required'),
  // license_number:yup.string().nullable().optional(),
  license_number:yup.string().matches(licenseDriverRegex, "Invalid License Number").required(`DMV License Number is Required`),
  dmv_license_state: yup
  .object()
  .required("DMV License State is required")
  .typeError("DMV License State is required"),
  company: yup.string().nullable().optional(),
  ticket_type:yup.object().required('Ticket Type is Required'),
  ticket_date:yup.date().required(`Ticket Date is Required`).typeError('Ticket Date is Required'),
  subject:yup.string().trim().optional().nullable(),
  location:yup.object().required('County is Required'),
  police_officer_name:yup.string().trim().optional().nullable(),
  vin_number: yup
  .string()
  .typeError("Vehicle Identification Number(VIN) is required")
  .required("Vehicle Identification Number(VIN) is required"),
  dmv_license_plate_number: yup
  .string()
  .nullable(),
  description:yup.string().trim().optional().nullable(),
  priority:yup.string().trim().optional().nullable(),
  ticket_unique_id:yup
  .string()
  .trim()
  .min(10,"Min 10 characters")
  .max(10, "Max 10 characters")
  .required("Traffic Ticket Number is required"),
})

export function DriverEditValidation(values: any) {
  return handleErrorMeg(values, DriverEditValidationSchema);
}

export const DriverEditValidationSchema=yup.object().shape({
  // user_id:yup.string().required('Driver is Required'),
  ticket_type:yup.object().required('Ticket Type is Required'),
  ticket_date:yup.date().required(`Ticket Date is Required`).typeError('Ticket Date is Required'),
  subject:yup.string().trim().optional().nullable(),
  location:yup.object().required('County is Required'),
  police_officer_name:yup.string().trim().optional().nullable(),
  vin_number: yup
  .string()
  .typeError("Vehicle Identification Number(VIN) is required")
  .required("Vehicle Identification Number(VIN) is required"),
  dmv_license_plate_number: yup
  .string()
  .nullable(),
  license_number:yup.string().matches(licenseDriverRegex, "Invalid License Number").required(`DMV License Number is Required`),
  dmv_license_state: yup
  .object()
  .required("DMV License State is required")
  .typeError("DMV License State is required"),
  alt_mobile_number: phoneSchema(false).nullable().optional(),
  fax:phoneSchema(false).nullable().optional(),
  description:yup.string().trim().optional().nullable(),
  priority:yup.string().trim().optional().nullable(),
  ticket_unique_id:yup
  .string()
  .trim()
  .min(10,"Min 10 characters")
  .max(10, "Max 10 characters")
  .required("Traffic Ticket Number is required"),
})

export function EditCreationValidation(values: any) {
  return handleErrorMeg(values, EditCreationValidationSchema);
}

export const EditCreationValidationSchema=yup.object().shape({
  ticket_type:yup.object().required('Ticket Type is Required'),
  ticket_date:yup.date().required(`Ticket Date is Required`).typeError('Ticket Date is Required'),
  location:yup.object().required('County is Required'),
  subject:yup.string().trim().optional().nullable(),
  description:yup.string().trim().optional().nullable(),
  police_officer_name:yup.string().trim().optional().nullable(),
  priority:yup.string().trim().optional().nullable(),
  vin_number: yup
  .string()
  .typeError("Vehicle Identification Number(VIN) is required")
  .required("Vehicle Identification Number(VIN) is required"),
  dmv_license_plate_number: yup
  .string()
  .nullable(),
  isAssgnee: yup.boolean(),
  status:yup.object().required('Status is Required'),
  // assignee:yup.object().required('Assignee is Required'),
  assignee: yup.string().when('isAssgnee', {
    is: true,
    then: () => yup.object().required('Assignee is Required'),
    otherwise: () => yup.object().nullable().optional()
  }),
  ticket_unique_id:yup
  .string()
  .trim()
  .min(10,"Min 10 characters")
  .max(10, "Max 10 characters")
  .required("Traffic Ticket Number is required"),
})


export function RejectFormDataValidation(values: any) {
  return handleErrorMeg(values, RejectFormDataValidationSchema);
}

export const RejectFormDataValidationSchema=yup.object().shape({
  reasonType:yup.object().required('Reason Type is Required'),
  comments:yup.string().trim().max(1000, "Max 50 Characters").required('Comments is Required'),
})

export function CloseFormDataValidation(values: any) {
  return handleErrorMeg(values, RejectFormDataValidationSchema);
}

export const CloseFormDataValidationSchema=yup.object().shape({
  reasonType:yup.object().required('Reason Type is Required'),
  comments:yup.string().trim().max(1000, "Max 50 Characters").required('Comments is Required'),
})

const checkIsValidString = (value:any) => {
    if(!!value.length){
      return true
    }
    return false
}
export function FilterFormDataValidation(values: any) {
  return handleErrorMeg(values, FilterFormDataValidationSchema);
}

export const FilterFormDataValidationSchema = yup.object().shape({
  status:yup.array().nullable().optional(),
  location:yup.array().nullable().optional(),
  payment:yup.array().nullable().optional(),
  ticket_type:yup.array().nullable().optional(),
  from: yup.date().nullable().optional(),
  to: yup.date().when('from', {
    is: (from: any) => from,
    then: () => yup.date().min(yup.ref('from'), "To Date must be Greater Than From Date").required(" To Date is Required").typeError("From Date is Required"),
    otherwise:() => yup.date().nullable().optional(),
  })
})



export function PaymentFormDataValidation(values: any) {
  return handleErrorMeg(values, PaymentFormDataValidationSchema);
}

export const PaymentFormDataValidationSchema = yup.object().shape({
  payment_type:yup.string().required('Payment Method is Required'),
  payment_date:yup.date().required('Payment Method is Required').typeError('Payment Date is Required'),
  payment_id:yup.string().required('Transaction ID is Required'),
  comments:yup.string().required('Comments is Required'),
})

export function DecisionFormDataValidation(values: any) {
  return handleErrorMeg(values, DecisionFormDataValidationSchema);
}

export const DecisionFormDataValidationSchema = yup.object().shape({
  lawyer_decision_comments:yup.string().required('Comments is Required').typeError("Comments is Required"),
})


export function HearingFormDataValidation(values: any) {
  return handleErrorMeg(values, HearingFormDataValidationSchema);
}

export const HearingFormDataValidationSchema = yup.object().shape({
  court_name:yup.string().required('Court Name is Required'),
  hearing_date:yup.date().required('Hearing Date is Required').typeError('Hearing Date is Required'),
  comments:yup.string().required('Comments is Required'),
})


export function QueueActionBulkDataValidation(values: any) {
  return handleErrorMeg(values, QueueActionDataBulkValidationSchema );
}

export const QueueActionDataBulkValidationSchema=yup.object().shape({
  isAssgnee: yup.boolean(),
  assignee: yup.object().required('Assignee is Required'),
})
export function OperatingLocationValidation(values:any){
  return handleErrorMeg(values, OperatingLocationValidationSchema);
}

export const OperatingLocationValidationSchema = yup.object().shape({
  services:yup.array().of(
    yup.object().shape({
      value: yup.string().required('Please select a value.'), // Customize your validation rules
      name: yup.string().required('Please select a value.'),  // Customize your validation rules
    })
  )
  .min(1, 'Services is Required.'),
  operatingLocation:yup.array().of(
    yup.object().shape({
      value: yup.string().required('Please select a value.'), // Customize your validation rules
      name: yup.string().required('Please select a value.'),  // Customize your validation rules
    })
  )
  .min(1, 'Operating Locations is Required.'),
})
export function AddReviewValidation(values: any) {
  return handleErrorMeg(values, AddReviewSchema);
}

export const AddReviewSchema = yup.object().shape({
  rating:  yup
  .string()
  .trim()
  .matches(/^(?!0)/, "Invalid Rating")
  .required("Rating is required"),
  feedback:yup.string().required('Description is Required').typeError("Description is Required"),
});

export const DecisionInputFieldSchema=yup.object().shape({
  // original_points:yup.string().required('Points Gained is Required'),
  // reduced_points:yup.string().required('Points Saved is Required'),
  // fine_amount:yup.string().required('Fine Amount is Required'),
})
export function DecisionInputField(values:any){
  return handleErrorMeg(values, DecisionInputFieldSchema);
}

