import { Box, Stack } from "@mui/system";
import Chart from "react-apexcharts";
import { Chip, Grid, Typography } from "@mui/material";
import MainContainerWrapper from "atic-common-helpers/wrappers/MainContainerWrapper";
import DonutChart from "./DonutChart";
import BarChart from "./BarChart";
import { AutoCompleteField } from "layout/hoc/formField";
import { useState } from "react";

// Donut chart data
const donutLabels = [
  "Registered users",
  "Excelsior Representatives",
  "Both",
];
const donutSeries = [60, 10, 30];

//Bar chart data
const barXLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"];

const barSeries = [
  {
    name: "Registered users",
    data: [120, 100, 300, 600, 200, 80, 350, 880],
  },
  {
    name: "Excelsior Representatives",
    data: [160, 364, 345, 956, 243, 234, 740, 300],
  },
  {
    name: "Both",
    data: [500, 200, 600, 123, 654, 832, 223, 745],
  },
];

//Area chart data
const areaState = {
  options: {
    chart: {
      id: "line-chart",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"], // Months on X-axis
      title: {
        text: "Month",
        style: {
          fontWeight: "none",
        },
      },
    },
    yaxis: {
      min: 0, // Set the minimum value of the y-axis to 0
      tickAmount: 5, // Display 5 ticks on the y-axis
      tickInterval: 200, // Set the interval between ticks to 200
      labels: {
        show: false, // Hide y-axis labels
      },
    },
    colors: ["#004282", "#8aabcb", "#e6edf4"],
    markers: {
      size: 6, // Adjust the size of the markers
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any, { seriesIndex, dataPointIndex, w }: any) {
        return w.globals.seriesNames[seriesIndex]; // Display custom label based on series name
      },
    },
    // stroke: {
    //   curve: "smooth", // Use smooth curve for connecting points
    // },
  },
  series: [
    {
      name: "BK", // Location name
      data: [
        { x: "Jan", y: 320 },
        { x: "Feb", y: 340 },
        { x: "Mar", y: 360 },
        { x: "Apr", y: 380 },
        { x: "May", y: 400 },
        { x: "Jun", y: 420 },
        // Add more data points...
      ],
    },
    {
      name: "CA", // Location name
      data: [
        { x: "Jan", y: 200 },
        { x: "Feb", y: 230 },
        { x: "Mar", y: 250 },
        { x: "Apr", y: 270 },
        { x: "May", y: 290 },
        { x: "Jun", y: 300 },
        // Add more data points...
      ],
    },
    {
      name: "NY", // Location name
      data: [
        { x: "Jan", y: 120 },
        { x: "Feb", y: 140 },
        { x: "Mar", y: 150 },
        { x: "Apr", y: 190 },
        { x: "May", y: 200 },
        { x: "Jun", y: 240 },
        // Add more data points...
      ],
    },
  ],
};

const Charts = () => {

  return (
    <MainContainerWrapper>
      <Stack mt={5} sx={{ paddingLeft: 5, paddingRight: 5 }}>
        <Grid container direction="row" spacing={3} pt={2}>
          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                border: {
                  xs: "none",
                  md: "1px solid #dddddd",
                  lg: "1px solid #dddddd",
                },
                borderRadius: "10px",
              }}
              pt={2}
              pl={2}
              width="100%"
              height={'100%'}
            >
              <Typography fontWeight={600}>Excelsior Users</Typography>
              <Box mt={3} mb={4}>
                <DonutChart
                  donutLabels={donutLabels}
                  donutSeries={donutSeries}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                border: {
                  xs: "none",
                  md: "1px solid #dddddd",
                  lg: "1px solid #dddddd",
                },
                borderRadius: "10px",
              }}
              pt={2}
              pl={2}
            >
              <Typography fontWeight={600}>User Onboarded</Typography>
              <Box mt={3} mb={4}>
                <BarChart barXLabels={barXLabels} barSeries={barSeries} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Box
              sx={{
                border: {
                  xs: "none",
                  md: "1px solid #dddddd",
                  lg: "1px solid #dddddd",
                },
                borderRadius: "10px",
              }}
              pt={2}
              pl={2}
            >
              <Typography fontWeight={600}>
                User Onboarded - Based on Location
              </Typography>
              <Box mt={3} mb={4}>
                <Chart
                  options={areaState.options}
                  series={areaState.series}
                  type="area"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </MainContainerWrapper>
  );
};

export default Charts;
