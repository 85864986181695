import axios from "axios";
import { keycloakValue } from "pages/authentication/keycloak/useKeyCloak";

const mainAxios = process.env.REACT_APP_END_URL;
const DCUrl = process.env.REACT_APP_DC_END_URL;


export const IsBaseURL = (setCommonCode = true, URLName: any = 'FHT') => {
  // axios.defaults.baseURL=mainAxios;
  axios.defaults.baseURL = (setCommonCode ? mainAxios : DCUrl);
}

// Add a flag to track if the 401 alert has been shown
let isAlertShown = false;
export const setAxiosDefauls = () => {
  axios.interceptors.request.use(onRequestFulfilled, onRequestRejected);

  axios.interceptors.response.use(onResponseFulfilled, onResponseRejected);
};

export const resetSession = () => {
  localStorage.remove("at-fht-auth");
  // history.push('/');
};

const onRequestFulfilled = async (config: any) => {
  let token = localStorage.getItem('at-fht-token');
  if (token && token != 'undefined') {
    if (keycloakValue?.updateToken) {
      const refreshToken = await keycloakValue.updateToken(300).then((ses: any) => ses).catch(err => console.log('err', err));
      if (refreshToken)
        localStorage.setItem(
          "at-fht-token",
          keycloakValue?.token || ''
        );
    }
    token = localStorage.getItem('at-fht-token');
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.role = `Bearer ${token}`;
  }
  return Promise.resolve(config);
};

const onRequestRejected = (error: any) => {
  return Promise.reject(error);
};

const onResponseFulfilled = (config: any) => {
  return Promise.resolve(config);
};

const onResponseRejected = (error: any) => {
  if (
    // (error?.response?.status === 403 && !isAlertShown)|| 
    error?.response?.status === 401) {
    localStorage.clear();
    // alert("Session Expired");
    window.location.href = "/login";
    // Set the flag to true after showing the alert
    // isAlertShown = true;
  }
  return Promise.reject(error);
};
